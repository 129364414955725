import React from 'react'

export const BaldeSoldado350 = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="1191"
    height="802"
    viewBox="0 0 1191 802"
    {...props}
  >
    <defs>
      <pattern id="pattern" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 253 236">
        <image width="253" height="236" href="../baldesoldado-350/BS-350-PA01.322.13E.1701_0008_Camada-11.png" />
      </pattern>
      <pattern id="pattern-2" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 207 247">
        <image width="207" height="247" href="../baldesoldado-350/BS-350-PA01.322.13E.1701_0007_Camada-10.png" />
      </pattern>
      <pattern id="pattern-3" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 73 101">
        <image width="73" height="101" href="../baldesoldado-350/BS-350-PA01.322.13E.1701_0001_Camada-4.png" />
      </pattern>
      <pattern id="pattern-4" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 32 25">
        <image width="32" height="25" href="../baldesoldado-350/BS-350-PA01.322.13E.1701_0002_Camada-5.png" />
      </pattern>
      <pattern id="pattern-5" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 84 102">
        <image width="84" height="102" href="../baldesoldado-350/BS-350-PA01.322.13E.1701_0003_Camada-6.png" />
      </pattern>
      <pattern id="pattern-6" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 26 29">
        <image width="26" height="29" href="../baldesoldado-350/BS-350-PA01.322.13E.1701_0004_Camada-7.png" />
      </pattern>
      <pattern id="pattern-7" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 114 63">
        <image width="114" height="63" href="../baldesoldado-350/BS-350-PA01.322.13E.1701_0005_Camada-8.png" />
      </pattern>
      <pattern id="pattern-8" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 21 24">
        <image width="21" height="24" href="../baldesoldado-350/BS-350-PA01.322.13E.1701_0006_Camada-9.png" />
      </pattern>
      <pattern id="pattern-9" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 30 32">
        <image width="30" height="32" href="../baldesoldado-350/BS-350-PA01.322.13E.1701_0009_Camada-12.png" />
      </pattern>
      <pattern id="pattern-10" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 82 91">
        <image width="82" height="91" href="../baldesoldado-350/BS-350-PA01.322.13E.1701_0012_Camada-15.png" />
      </pattern>
      <pattern id="pattern-11" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 65 47">
        <image width="65" height="47" href="../baldesoldado-350/BS-350-PA01.322.13E.1701_0011_Camada-14.png" />
      </pattern>
      <pattern id="pattern-12" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 89 104">
        <image width="89" height="104" href="../baldesoldado-350/BS-350-PA01.322.13E.1701_0013_Camada-16.png" />
      </pattern>
      <pattern id="pattern-13" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 126 104">
        <image width="126" height="104" href="../baldesoldado-350/BS-350-PA01.322.13E.1701_0014_Camada-17.png" />
      </pattern>
      <pattern id="pattern-14" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 70 138">
        <image width="70" height="138" href="../baldesoldado-350/BS-350-PA01.322.13E.1701_0015_Camada-18.png" />
      </pattern>
      <pattern id="pattern-15" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 91 84">
        <image width="91" height="84" href="../baldesoldado-350/BS-350-PA01.322.13E.1701_0016_Camada-19.png" />
      </pattern>
      <pattern id="pattern-16" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 43 29">
        <image width="43" height="29" href="../baldesoldado-350/BS-350-PA01.322.13E.1701_0017_Camada-20.png" />
      </pattern>
      <pattern id="pattern-17" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 35 45">
        <image width="35" height="45" href="../baldesoldado-350/BS-350-PA01.322.13E.1701_0018_Camada-21.png" />
      </pattern>
      <pattern id="pattern-18" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 101 150">
        <image width="101" height="150" href="../baldesoldado-350/BS-350-PA01.322.13E.1701_0019_Camada-22.png" />
      </pattern>
      <pattern id="pattern-19" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 162 70">
        <image width="162" height="70" href="../baldesoldado-350/BS-350-PA01.322.13E.1701_0022_Camada-25.png" />
      </pattern>
      <pattern id="pattern-20" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 13 30">
        <image width="13" height="30" href="../baldesoldado-350/BS-350-PA01.322.13E.1701_0021_Camada-24.png" />
      </pattern>
    </defs>
    <g
      id="Grupo_77"
      data-name="Grupo 77"
      transform="translate(-359 -144)"
      className="vista-g"
    >
      <rect id="BS-350-PA01.322.13E.1701_0008_Camada-11" width="262" height="245" transform="translate(559 262)" fill="url(#pattern)" />
      <rect id="BS-350-PA01.322.13E.1701_0007_Camada-10" width="214" height="256" transform="translate(288 387)" fill="url(#pattern-2)"
        data-name="Cremalheira de Ferro Fundido 350-400-410"
        data-url="https://shopirbal.com/pt/produto/cremalheira-de-ferro-fundido-350-400-410/" />
      <image id="BS-350-PA01.322.13E.1701_0000_Camada-3" width="122" height="152" transform="translate(291 792)" href="../baldesoldado-350/BS-350-PA01.322.13E.1701_0000_Camada-3.png" />
      <rect id="BS-350-PA01.322.13E.1701_0001_Camada-4" width="75" height="105" transform="translate(471 779)" fill="url(#pattern-3)" />
      <rect id="BS-350-PA01.322.13E.1701_0002_Camada-5" width="33" height="26" transform="translate(590 783)" fill="url(#pattern-4)" />
      <rect id="BS-350-PA01.322.13E.1701_0003_Camada-6" width="87" height="106" transform="translate(639 680)" fill="url(#pattern-5)" />
      <rect id="BS-350-PA01.322.13E.1701_0004_Camada-7" width="27" height="30" transform="translate(756 633)" fill="url(#pattern-6)" />
      <rect id="BS-350-PA01.322.13E.1701_0006_Camada-9" width="21" height="25" transform="translate(958 525)" fill="url(#pattern-8)" />
      <image id="BS-350-PA01.322.13E.1701_0010_Camada-13" width="393" height="465" transform="translate(798 451)" href="../baldesoldado-350/BS-350-PA01.322.13E.1701_0010_Camada-13.png" />
      <rect id="BS-350-PA01.322.13E.1701_0009_Camada-12" width="31" height="33" transform="translate(900 667)" fill="url(#pattern-9)"
        data-name="Pinhão/Carreto B3 + CAVILHA"
        data-url="https://shopirbal.com/pt/produto/pinhao-carreto-b3-cavilha/" />
      <rect id="BS-350-PA01.322.13E.1701_0012_Camada-15" width="85" height="94" transform="translate(1194 470)" fill="url(#pattern-10)" />
      <rect id="BS-350-PA01.322.13E.1701_0011_Camada-14" width="67" height="49" transform="translate(1103 387)" fill="url(#pattern-11)"
        data-name="Rolamento do Lado do Pinhão RL 6205 2RS"
        data-url="https://shopirbal.com/pt/produto/rolamento-rl-6205-2rs/" />
      <rect id="BS-350-PA01.322.13E.1701_0013_Camada-16" width="92" height="108" transform="translate(1217 773)" fill="url(#pattern-12)"
        data-name="Roda Maciça Ø400"
        data-url="https://shopirbal.com/pt/produto/roda-macica-400/" />
      <rect id="BS-350-PA01.322.13E.1701_0014_Camada-17" width="131" height="108" transform="translate(1194 630)" fill="url(#pattern-13)" />
      <rect id="BS-350-PA01.322.13E.1701_0015_Camada-18" width="73" height="143" transform="translate(1338 551)" fill="url(#pattern-14)"
        data-name="Correia PV 10PJ 1355"
        data-url="https://shopirbal.com/pt/produto/correia-pv-10pj-1355/" />
      <rect id="BS-350-PA01.322.13E.1701_0016_Camada-19" width="95" height="87" transform="translate(1499 568)" fill="url(#pattern-15)"
        data-name="Motor ELE 2,00HP"
        data-url="https://shopirbal.com/pt/produto/motor-ele-2-00hp/" />
      <rect id="BS-350-PA01.322.13E.1701_0017_Camada-20" width="45" height="30" transform="translate(1587 701)" fill="url(#pattern-16)"
        data-name="Condensador 2HP"
        data-url="https://shopirbal.com/pt/produto/condensador-2hp/" />
      <rect id="BS-350-PA01.322.13E.1701_0018_Camada-21" width="36" height="47" transform="translate(1494 768)" fill="url(#pattern-17)"
        data-name="Interruptor B3+B4"
        data-url="https://shopirbal.com/pt/produto/interruptor-b3b4/" />
      <rect id="BS-350-PA01.322.13E.1701_0019_Camada-22" width="104" height="156" transform="translate(1253 310)" fill="url(#pattern-18)" />
      <image id="BS-350-PA01.322.13E.1701_0020_Camada-23" width="166" height="258" transform="translate(1365 219)" href="../baldesoldado-350/BS-350-PA01.322.13E.1701_0020_Camada-23.png" />
      <rect id="BS-350-PA01.322.13E.1701_0022_Camada-25" width="168" height="73" transform="translate(1364 136)" fill="url(#pattern-19)" />
      <image id="B3-350-PA01.321.13E.1701_0008_Camada-11" width="123" height="66" transform="translate(798 468)" href="../baldesoldado-350/BS-350-PA01.322.13E.1701_0005_Camada-8.svg"
        data-name="Rolamento do Cubo do Balde RL 32011 X"
        data-url="https://shopirbal.com/pt/produto/rolamento-do-cubo-do-balde-rl-32011-x/" />
      <rect id="BS-350-PA01.322.13E.1701_0021_Camada-24" width="13" height="31" transform="translate(1595 234)" fill="url(#pattern-20)" />
    </g>
  </svg>
)
